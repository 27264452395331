<template>
	<div>
		<v-form class="c-container" v-model="valid" ref="form">
			<div>
				<div class="c-one-title mt-2">景区信息</div>
				<div class="mt-10">
					<v-row>
						<v-col cols="3">
							<v-text-field :rules="checkRules('景区地址')" label="景区地址" class="asterisk" v-model="form.address"/>
						</v-col>
						<v-col cols="3">
							<v-text-field :rules="checkRules('联系电话')" label="联系电话" class="asterisk" v-model="form.tel"/>
						</v-col>
						<v-col cols="3">
							<v-text-field :rules="checkRules('入口经度')" label="入口经度" class="asterisk" v-model="form.longitude"/>
						</v-col>
						<v-col cols="3">
							<v-text-field :rules="checkRules('入口维度')" label="入口纬度" class="asterisk" v-model="form.latitude"/>
						</v-col>
					</v-row>
					<!--					<v-row>-->
					<!--						<v-col cols="3">-->
					<!--							<v-text-field :rules="checkRules('入口许可证')" label="景区许可证" class="asterisk" v-model="form.permit"/>-->
					<!--						</v-col>-->
					<!--					</v-row>-->
				</div>
			</div>
			<div>
				<div class="c-one-title mt-6">系统设置</div>
				<div class="c-second-title mb-6">系统名称</div>
				<v-row>
					<v-col cols="3">
						<v-text-field :rules="checkRules('系统主标题')" label="系统主标题" class="asterisk" v-model="form.title"/>
					</v-col>
					<v-col cols="3">
						<v-text-field
								:rules="checkRules('系统副标题')" label="系统副标题" class="asterisk"
								v-model="form.subtitle"/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<div class="c-second-title">系统LOGO</div>
						<v-row class="mt-4">
							<v-col cols="6">
								<material
										subTitle="LOGO整体上传区域，点击添加图像上传图像，图片建议：宽高比例1:1，大小不能超过2MB，格式仅限于jpg、png、svg"
										@change="form.weblogo = $event"
										:valueImage="form.weblogo" :showAddIcon="false"/>
							</v-col>
							<v-col cols="6">
								<material
										subTitle="LOGO图标上传区域，点击添加图像上传图像，图片建议：宽高比例1:1，大小不能超过2MB，格式仅限于jpg、png、svg"
										@change="form.webicon = $event"
										:valueImage="form.webicon" :showAddIcon="false"/>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="6">
						<div class="c-second-title">登录背景图</div>
						<material
								subTitle="点击添加图像新增上传区域，拖动选中的文件区域可对其排序，图片建议：宽高1152*1080像素，大小不能超过2MB，格式仅限于jpg、png" @change="form.loginpicurl = $event"
								:valueImage="form.loginpicurl" :showAddIcon="false"/>
					</v-col>
				</v-row>
			</div>
		</v-form>
		<div
				style="background-color: #ffffff;
				padding: 20px 0 20px 24px;
				bottom: 0;
				margin-left: -12px;
				position: fixed;
				width: 100%"
		>
			<v-btn @click="reset" depressed outlined color="primary">重置</v-btn>
			<v-btn
					:disabled="!operatePermissions.includes('850005')"
					@click="confirm"
					depressed
					color="primary"
					style="margin-left: 24px">保存
			</v-btn>
		</div>
	</div>
</template>

<script>

import material from "@/components/common-material/material.vue";
import api from '@/api/systemManage/common-setting.js'

export default {
	components: {material},
	data() {
		return {
			valid: true,
			form: {
				address: '',
				tel: '',
				longitude: '',
				latitude: '',
				// permit: '',
				title: '',
				subtitle: ''
			},
			defaultFileType: [
				{range: 'image/png', suffixs: ['png']},
				{range: 'image/jpeg', suffixs: ['jpg']},
				{range: 'image/svg+xml', suffixs: ['svg']}
			],
			operatePermissions: []
		}
	},
	methods: {
		checkRules(text) {
			return [(v) => v !== "" || `请输入` + text];
		},
		reset() {
			this.$refs.form.reset();
		},
		confirm() {
			let validate = this.$refs.form.validate();
			if (validate) {
				this.axios.post(api.save, this.form).then((res) => {
					if (res.code === this.staticVal.Code.Success) {
						this.snackbar.success('保存成功');
						localStorage.setItem("common-setting", JSON.stringify(this.form));
					}
				});
			}
		}
	},
	mounted() {
		let str = sessionStorage.getItem('operatePermissionKey');
		if (str) {
			this.operatePermissions = JSON.parse(str);
		}
		this.axios.post(api.load, {}).then((res) => {
			if (res.code === this.staticVal.Code.Success) {
				this.form = res.data;
			}
		});
	}
}
</script>

<style scoped lang="scss">
.c-one-title {
	font-size: 18px;
	font-weight: bold;
	border-left: 2px solid var(--v-primary-base);
	padding-left: 8px;
	color: #333333;
}

.c-second-title {
	font-weight: bold;
	font-size: 16px;
	color: #333333;
	margin-top: 18px;
	margin-bottom: 16px
}

.c-container {
	overflow-y: auto;
	overflow-x: hidden;
	height: calc(100vh - 72px - 56px);
	margin-top: -12px;
	margin-right: -12px;
	padding: 10px;
}

::v-deep {
	.v-label {
		font-size: 14px;
	}

	.col-6 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.v-text-field {
		padding-top: 0;
	}
}
</style>
